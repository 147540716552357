.footer{
    /* background-image: linear-gradient(#00365f,#033961); */
    background-color: #3375b8;
    color: aliceblue;
    float: left;
    padding: 40px 10%;
    position: relative;
    width: 100%;
    font-size: 18px;
}
.footer h5{
    font-size: 21px !important;
}
.footer img{
    height: 60px;
    float: right;
}
.footer .copyright{
    float: right;
    font-size: 16px;
}

.footer.weact{
    /* background-image: linear-gradient(#00365f,#033961); */
    background-color: white;
    color: black;
    float: left;
    font-family: Arial, Helvetica, sans-serif;
    padding: 40px 10%;
    position: relative;
    width: 100%;
    font-size: 18px;
    top: -5px;
    z-index: 9999;
}
.footer.weact h5{
    font-size: 21px !important;
    font-family: Arial, Helvetica, sans-serif;
}
.footer.weact img{
    height: 116px;
    float: right;
}
.footer.weact .copyright{
    float: right;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
}
/* footer soulful */

.footer.soulful{
    /* background-image: linear-gradient(#00365f,#033961); */
    background-color: #000;
    color: black;
    float: left;
    padding: 40px 10% 10px 10%;
    position: relative;
    width: 100%;
    font-size: 18px;
    /* top: -5px; */
    z-index: 9999;
}
.footer.soulful h5{
    font-size: 21px !important;
    color: #fff;
}
.footer.soulful img{
    height: 116px;
    float: right;
}
.footer.soulful .copyright{
    float: right;
    color: #fff;
    font-size: 16px;
}

.footer-image-outer-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 80%;
}

.footer-image-inner-container {
    width: 31%;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
}

@media (max-width: 659px){    

    .footercontent.soulful{
        margin-top: 120px;   
    }

    .footer-image-outer-container {
        width: 100%;
    }

    .footer-image-inner-container {
        width: 100%;
        justify-content: space-around;
    }
}

@media (max-width: 549px){
    
    .footer{
        display:grid;
    }

    .footer.weact {
        display:grid;
    }

    .footer.soulful {
        display:grid;
    }

    .footer-image-outer-container {
        top: 30px;
    }
}
