.checkboxlabel{
    font-size: 20px;
    margin-block: 5px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    gap: 10px;
}


.checkboxlabel input{
    width: 30px;    
    height: 18px;
}

.checkboxlabel span{
    width: calc(100% - 30px);    
    color: #5b5c5d;
}