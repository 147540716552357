.main-container {
    /* background-image: "url('/V2/background-image.png')"; */
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.main-question-container {
    padding-inline: 120px;
}
/* Powered by Learn & Innovate for Education, Enablement, Empowerment */
.inner-question-container {
    /* border: 1.321px solid #339FE0; */
    background: white;
    padding-inline: 70px;
    box-shadow: 0px 30px 20px rgba(185, 202, 217, 0.43);
}

.react-select .select__control {
    min-height: 52px;
}

.single-group-child {
    box-shadow: 0px 30px 20px rgba(185, 202, 217, 0.43);
}

.single-group-child.no-shadow {
    box-shadow: none;
}

.inner-question-container.no-bg {
    border: none;
    background: transparent;
    padding-inline: 70px;
}

.btn-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.btn-container .inner-btn-container {
    width: 500px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-block: 40px;
}

.btn-container .inner-btn-container button:nth-child(1) {
    background-color: #A8A8A8;
    font-size: 16px;
    font-weight: 500;
    border: none;
    color: #000;
    height: 70px;
    width: 200px;
}

.btn-container .inner-btn-container button:nth-child(2) {
    background-color: #0096D8;
    font-size: 16px;
    color: #000;
    height: 70px;
    width: 200px;
    font-weight: 500;
    border: none;
}

.submitted-image-2 {
    width: 500px !important;
}

@media screen and (max-width: 1200px) {

    .main-question-container {
        padding-inline: 30px;
    }

    .inner-question-container.no-bg {
        padding-inline: 20px !important;
    }
}

@media screen and (max-width: 700px) {
    .main-question-container {
        padding-inline: 30px;
    }

    .inner-question-container.no-bg {
        padding-inline: 10px !important;
    }
}


@media screen and (max-width: 600px) {
    .submitted-image-2 {
        width: 400px !important;
    }  
}

@media screen and (max-width: 500px) {
    .main-question-container {
        padding-inline: 10px;
    }

    .btn-container .inner-btn-container {
        flex-direction: column;
        margin-block: 30px;
        gap: 20px;
    }

    .inner-question-container.no-bg {
        padding-inline: 5px !important;
    }

    .submitted-image-2 {
        width: 300px !important;
    }  
}