.question-element {
    font-weight: 600;
    font-size: 18px;
    /* margin-bottom: 10px; */
}

.option-container {
    display: flex;
    justify-items: flex-start;
    align-items: flex-start;
    margin-block: 5px;
    gap: 0px;
}

.option-container.align-start{
    display: flex;
    justify-items: flex-start;
    align-items: flex-start;
    margin-block: 5px;
    gap: 5px;
}

.option-container input{
    width: 30px;  
    height: 18px;
    margin-top: 5px;
}

.option-container span{
    width: calc(100% - 30px);    
}

.required {
    color: red;
}

.error-in-question {
    border: 1px solid #ff303078;
    box-shadow: 1px 1px 8px 0px #ff000091;
    position: relative;
}

.error-message-container {
    width: 100%;
    /* background: #e53935; */
    padding: inherit;
    color: red;
    font-weight: 600;
}

.mb-3 {
    margin-bottom: 3px !important;
}

.mt-3 {
    margin-top: 3px !important;
}

.check-option.hide {
    display: none !important;
}


.check-option.show {
    /* display: contents; */
}

@media screen and (max-width: 600px) {
    .option-container {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }

    .option-container input{
        margin-top: 5px;  
    }
}