@font-face {
    font-family: 'Rubik';
    src: url('./assets/Rubik-Light.woff2') format('woff2'),
        url('./assets/Rubik-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

@import url(https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css);
/* @import url(./assets/main.css); */



.body{
    /* background-image: url('./backgroundimage.png') ; */
    background-attachment: fixed;
    background-size:cover;
    background-position: 50%;
    height: 100%;
    width: 100%;
    overflow: auto;   
    font-family: "Rubik", sans-serif;
}

.body.weact{
    background-color: white !important;
    background-image: none !important;
    background-attachment: fixed;
    background-size:cover;
    background-position: 50%;
    height: 100%;
    width: 100%;
    overflow: auto;   
    font-family: Arial, Helvetica, sans-serif;
}

.body.weact .card{
   top: -1px;
}

strong {
    font-weight: 600;
}

.button{
    background-color: #ec9a00;
    border-radius: 10px;
    box-shadow: 0 7px 11px rgb(0 0 0 / 50%);
    font-family: Montserrat,sans-serif;
    font-size: 30px;
    font-weight: 650;
    margin-bottom: 67px;
    padding: 10px;
    color: aliceblue;
    border-color:  #0d6efd;
    width: 100%;
    height: 100%;
    min-height: 100px;

}

.button:hover{
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.buttonsdiv{
    position: relative;
    height: 560px;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    column-gap: 20px;
    display: grid;
    align-items: center; 
}
.body > div{
    width: 100%;
}

.adnyButtonsDiv {
    height: 90vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


.adnyButtonsDiv button {
    height: fit-content;
}

.single-group-child {
    background: #fff;
    color: #fff !important;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
}

.single-group-child.soulful-application {
    background: none !important;
    color: #fff !important;
    margin-bottom: 10px;
    padding-block: 10px;
    padding-inline: 7px !important;
    width: 100%;
    border-radius: 5px;
}
/* special-components */

.single-group-child.soulful-application.special-component {
    background: white !important;
    color: #fff !important;
    margin-bottom: 20px;
    padding-block: 22px;
    padding-inline: 22px !important;
    width: 100%;
    border-radius: 4px;
}

.single-group-child.soulful-application.special-component.disabled {
    background: #89d3c5 !important;
}

.single-group-child.soulful-application.dob {
    background: none !important;
    color: #fff!important;
    margin-bottom: 10px;
    padding-block: 10px;
    padding-inline: 7px !important;
    width: 100%;
}
/* 
.single-group-child.soulful-application input {
   height: 53px;
} */

.button-primary {
    background-color: rgb(13, 126, 211);
    border-radius: 5px;
    padding: 9px !important;
    color: white;
    border: none;
}

.button-secondary {
    background-color: gray;
    border-radius: 5px;
    padding: 9px !important;
    color: white;
    border: none;
}

.single-elements-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
}

.weact-question-container {
    /* background-color: #398E3B1A; */
    padding-inline: 70px;
    padding-top: 10px;
    padding-bottom: 30px;
}

.soulful-question-container {
    /* background-color: #398E3B1A; */
    padding-top: 10px;
    padding-bottom: 30px;
}

.single-elements-container.weact {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    background-color: #398E3B1A;
    padding-inline: 70px;
    padding-top: 70px;
    padding-bottom: 0px;
}


.single-elements-container.soulful {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 95px;
    /* background-color: #398E3B1A; */
    padding-top: 20px;
    padding-bottom: 0px;
}

.single-element {
    min-height: 93%; 
    /* min-width: max-content;   */
}

@media screen and (max-width: 1100px) {
    .single-elements-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-column-gap: 10px;
    }
    .single-elements-container.weact {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-column-gap: 10px;
        padding-inline: 0px !important;
    }

    .single-elements-container.soulful {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-column-gap: 10px;
        padding-inline: 0px !important;
    }

    .weact-question-container {
        padding-inline: 0px;
        /* padding-top: 10px;
        padding-bottom: 30px; */
    }
}

.hide-question {
    display: none;
}

.text-bold {
    font-weight: 600;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number]{
  -moz-appearance: textfield !important;
}

/* Firefox */
input[type=numeric]{
  -moz-appearance: textfield;
}

.custom-datepicker {
    width: 100%;
    height: 53px;
}
 .datepicker {
    height: 53px;
 }

 .high-zindex {
    z-index: 9999;
 }

 .react-datepicker__input-container .react-datepicker__calendar-icon {
    position: absolute;
    right: 10px !important;
    top: 12px !important;
    height: 20px;
    width: 20px;
 }

input[type="date"] {
    padding-left: 10px !important;
    height: 53px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background-color: transparent;
    width: 50%;
    height: 90%;
    align-items: flex-end;
    background: white;
    z-index: 9999;
}

input[type="date"]:hover::-webkit-calendar-picker-indicator {
    width: 100%;
}

input[type="date"]::after {
   /* display: none; */
   background-color: red !important;
   height: 100px;
   width: 100px;
   position: absolute;
}


.language-switch .react-switch-bg>div:nth-child(2) {
    width: 70px !important;
}

.language-switch .react-switch-bg>div:nth-child(1) {
    width: 70px !important;
    margin-left: 7px;
    padding-top: 2px;
}

.language-switch .react-switch-bg>div {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
}

.language-switch .react-switch-bg span {
    color: white;
}

.language-switch-soulful {
    width: 70px !important;
    font-weight: 500;
    margin-right: 25px;
}

.language-switch-soulful .react-switch-bg > div {
    top: -2px;
}


.language-switch-soulful .react-switch-bg>div:nth-child(2) {
    width: 70px !important;
}

.language-switch-soulful .react-switch-bg>div:nth-child(1) {
    width: 70px !important;
    margin-left: 7px;
}

.language-switch-soulful .react-switch-bg span {
    color: white;;
    font-size: 14px;
    margin-bottom: 10px;
    /* margin-left: 5px; */
    /* padding: 10px; */
}

.react-switch-bg span {
    font-size: 15px;
    margin-top: 3px;
}

.react-select.soulful .select__control {
    height: 53px;
    background-color: white;
    border-color: transparent;
}

.react-select.soulful > div.select__control--is-disabled{
    background-color: #89D2C5 !important;
}

.textcomponent.soulful:disabled {
    background-color:white !important;

}

.form-control.soulful:disabled {
    background-color:white !important;
}

.react-select.soulful:disabled {
    background-color:white !important;
}

.textcomponent.soulful {
    height: 53px;
}

.text-font-sf {
    font-family: 'Montserrat', sans-serif;
}
/* 
.d-flex {
    display: flex !important;
} 

.justify-content-evenly {
    justify-content: space-evenly !important;
} 

.align-items-center {
    align-items: center !important;
} */