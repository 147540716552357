/* Header ---------------------------------- */
.header-container {
    padding-block: 32px;
    padding-inline: 42px;
    flex-wrap: wrap;
}

.header-inner-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.header-container h1 {
    font-size: 44px;
    font-weight: 800;
    line-height: 1;
    color: #000;
}

.header-container h3 {
    text-align: left;
    font-size: 22px;
    font-weight: 800;
    color: #000;
}

/* Paragraph -------------------------------- */

.paragraph-container {
    padding-inline: 45px;
}

.paragraph-container .heading {
    color: #000;
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 26px;
}

.paragraph-container .content {
    color: #000;
    font-size: 20px;
}

.paragraph-step-container {
    padding-inline: 120px;
}

.paragraph-step-container .heading{
    color: #307952;
    font-size: 37px;
    font-weight: 700;
}

.paragraph-step-container .content-container {
    width: 90%;
    background-color: rgba(255, 255, 255, 0.70);
    border: 1px solid #000;
}

.paragraph-step-container .no-border {
    background-color: transparent;
    border: none;
}

.content-container .heading {
    text-align: center;
    margin-top: 30px;
    font-weight: 700;
    color: #000;
}

.image-text-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-inline: 40px;
}

.image-text-container .image-container {
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* padding-right: 50px; */
}

.image-text-container .image-container img {
    width: 300px;
}

.image-text-container .text-container {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.image-text-container .text-container h1 {
    font-size: 24px;
    font-weight: 700;
}

.submitted-image {
    width: 350px !important;
}

.text-container-descrption-title {
    text-align: start;
}

.text-container-descrption-content {
    text-align: start;
}

/* Stepper --------------------------------- */

.stepper-container {
    margin-top: 40px;
    margin-bottom: 20px;
}

.inner-stepper-container {
    padding-inline: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.stepper-item-main-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.stepper-item-container {
    /* padding-inline: 50px; */
    align-self: center;
}

.stepper-item-image-container {
    position: relative;
}

.stepper-item-container .step-image {
    height: 68px;
    width: 86px;
}

.stepper-arrows {
    width: 150px;
    height: 20px;
}

.stepper-item-container .step-completed {
    height: 50px;
    width: 75px;
    position: absolute;
    left: 16px;
    top: 10px;
}

.stepper-item-heading {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.61);
}

.stepper-item-heading.active {
    font-weight: 700;
    color: #000;
}


.main-options-container.soulfulv2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.main-options-container.soulfulv2.onefr {
    display: grid;
    grid-template-columns: 1fr;
}

.main-options-container.soulfulv2.threefr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 1600px) {
    .image-text-container .image-container img {
        width: 400px !important;
    }
}

@media screen and (max-width: 1525px) {

    .stepper-item-main-container {
        gap: 10px;
    }

    .stepper-item-image-container img {
        width: 50px !important;
        height: 40px !important;
    }

    .stepper-item-image-container .stepper-arrows {
        width: 60px !important;
        height: 20px !important;
    }

    .stepper-item-heading {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 1350px) {
    .image-text-container .image-container img {
        width: 300px !important;
        height: 160px !important;
    }
}

@media screen and (max-width: 1110px) {

    .inner-stepper-container {
        padding-inline: 50px !important;
    }

    .stepper-item-main-container {
        width: 100% !important;
    }

    .stepper-item-main-container {
        gap: 10px;
    }

    .stepper-item-image-container img {
        width: 40px !important;
        height: 30px !important;
    }

    .stepper-item-image-container .stepper-arrows {
        width: 40px !important;
        height: 10px !important;
    }

    .stepper-item-heading {
        font-size: 13px !important;
    }
}

@media screen and (max-width: 1080px) {
    .image-text-container {
        flex-direction: column;
        padding-inline: 20px;
    }

    .image-text-container .image-container img {
        width: auto !important;
        /* height: 160px !important; */
    }

    .text-container-descrption-title {
        text-align: center !important;
    }
    
    .text-container-descrption-content {
        text-align: center !important;
    }
}

@media screen and (max-width: 1000px) {
    .content-container .heading {
        font-size: 30px;
    }

    .submitted-image {
        width: 'auto' !important;
    }
}


@media screen and (max-width: 900px) {
    .paragraph-step-container {
        padding-inline: 30px;
    }

    .main-options-container.soulfulv2 {
        grid-template-columns: 1fr 1fr;
    }

    .main-options-container.soulfulv2.threefr {
        grid-template-columns: 1fr 1fr !important;
    }

    .submitted-image {
        width: 'auto' !important;
    }

    .text-container-descrption-title {
        text-align: center;
    }
    
    .text-container-descrption-content {
        text-align: center;
    }
}


@media screen and (max-width: 808px) {

    .stepper-item-main-container {
        gap: 10px;
    }

    .stepper-item-image-container img {
        width: 30px !important;
        height: 20px !important;
    }

    .stepper-item-image-container .stepper-arrows {
        width: 30px !important;
        height: 6px !important;
    }

    .stepper-item-heading {
        font-size: 10px !important;
    }

    .inner-stepper-container {
        padding-inline: 30px !important;
    }

    .stepper-item-main-container {
        width: 100% !important;
    }
}


@media screen and (max-width: 700px) {

    .header-inner-container {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
    }

    .header-container h1 {
        font-size: 30px;
        text-align: center;
    }

    .header-container h3 {
        text-align: center;
        font-size: 18px;
        font-weight: 800;
        color: #000;
    }

    .header-container h5 {
        text-align: center;
        font-size: 16px;
        color: #000;
    }

    .paragraph-container {
        padding-inline: 35px;
    }

    .paragraph-container .heading {
        font-size: 24px;
    }

    .paragraph-container .content {
        font-size: 19px;
    }

    .paragraph-step-container .heading {
        font-size: 25px;
    }
    
    .content-container .heading {
        font-size: 25px;
    }

    .text-container-descrption-title {
        text-align: center;
    }

    .text-container-descrption-content {
        text-align: center;
    }

    .stepper-item-container .step-image {
        height: 38px;
        width: 46px;
    }


    .inner-question-container.no-bg {
        padding-inline: 10px !important;
    }
    
    .stepper-item-container .step-completed {
        height: 30px;
        width: 35px;
    }

    .stepper-arrows {
        width: 50px;
        height: 10px;
    }

    .stepper-item-heading {
        font-size: 14px;
        /* width: 50%; */
    }

    .stepper-item-heading.active {
        font-size: 14px;
    }

    .paragraph-step-container {
        padding-inline: 30px;
    }

    .image-text-container {
        flex-direction: column;
        padding-inline: 15px;
    }

    .image-text-container .image-container {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-right: 0px;
    }
    
    .image-text-container .text-container {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .image-text-container .image-container img {
        width: 100%;
    }

    .single-elements-container.inner-question-container {
        grid-template-columns: 1fr !important;
        padding-inline: 20px !important;
    }

    .submitted-image {
        width: 'auto' !important;
    }
}


@media screen and (max-width: 538px) {

    .header-container h5 {
        text-align: center;
        font-size: 14px;
        color: #000;
    }

    .stepper-item-main-container {
        gap: 10px;
    }

    .stepper-item-image-container img {
        width: 41px !important;
        height: 34px !important;
    }

    .stepper-item-image-container .stepper-arrows {
        width: 20px !important;
        height: 5px !important;
    }

    .stepper-item-heading {
        font-size: 8px !important;
    }

    .inner-stepper-container {
        padding-inline: 10px !important;
    }

    .stepper-item-main-container {
        width: 100% !important;
    }

    .stepper-item-container .step-completed {
        top: 0px !important;
        left: 8px !important;
    }
}


@media screen and (max-width: 560px) {
    .image-text-container .image-container img {
        width: 350px !important;
        height: 170px !important;
    }
}


@media screen and (max-width: 500px) {

    .main-container {
        background-size: auto !important;
    }
    
    .main-options-container.soulfulv2 {
        grid-template-columns: 1fr;
    }

    .header-container h3 {
        font-size: 15px;
    }

    .paragraph-container {
        padding-inline: 20px;
    }

    .paragraph-container .heading {
        font-size: 20px;
    }

    .content-container .heading {
        margin-top: 0px;
    }    

    .paragraph-container .content {
        font-size: 15px;
    }

    .paragraph-step-container .content-container {
        width: 100%;
    }

    .content-container .heading {
        margin-top: 20px;
        font-size: 20px;
    }


    .text-container-descrption-title {
        text-align: center;
    }

    .text-container-descrption-content {
        text-align: center;
    }


    .inner-stepper-container {
        padding-inline: 50px;
    }

    .stepper-item-container .step-image {
        height: 38px;
        width: 46px;
    }
    
    .stepper-item-container .step-completed {
        height: 30px;
        width: 35px;
    }

    .stepper-arrows {
        width: 50px;
        height: 10px;
    }

    .stepper-item-heading {
        font-size: 14px;
        /* width: 50%; */
    }

    .stepper-item-heading.active {
        font-size: 14px;
    }

    .paragraph-step-container {
        padding-inline: 10px;
    }

    .image-text-container {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-inline: 10px;
    }

    .main-question-container>div {
        padding-inline: 5px !important;
    }

    .image-text-container .text-container h1 {
        font-size: 18px;
    }

    .image-text-container .text-container h5 {
        font-size: 15px;
    }

    .single-elements-container.inner-question-container {
        grid-template-columns: 1fr !important;
    }

    .image-text-container .image-container img {
        width: 'auto' !important;
        height: 180px !important;
    }

    .submitted-image {
        width: 'auto' !important;
        height: 200px !important;
    }

    .main-options-container.soulfulv2.threefr {
        grid-template-columns: 1fr !important;
    }


    .stepper-item-heading {
        font-size: 8px !important;
        width: 60px;
    }
}

@media screen and (max-width: 400px) {

    .stepper-item-main-container {
        gap: 10px;
    }

    .stepper-item-image-container img {
        width: 20px !important;
        height: 10px !important;
    }

    .stepper-item-image-container .stepper-arrows {
        width: 16px !important;
        height: 5px !important;
    }

    .stepper-item-heading {
        font-size: 8px !important;
        width: 60px;
    }

    .inner-stepper-container {
        padding-inline: 0px !important;
    }

    .stepper-item-main-container {
        width: 100% !important;
    }
}


@media screen and (max-width: 420px) {
    .stepper-item-image-container img {
        width: 33px !important;
        height: 26px !important;
    }

    .stepper-item-heading {
        font-size: 7px !important;
        width: 29px;
    }

    .stepper-item-container .step-completed {
        top: 4px !important;
        left: 6px !important;
    }
}

@media screen and (max-width: 350px) {
    .stepper-item-image-container img {
        width: 25px !important;
        height: 18px !important;
    }

    .stepper-item-heading {
        font-size: 7px !important;
        width: 29px;
    }

    .stepper-item-container .step-completed {
        top: 0px !important;
        left: 2px !important;
    }
}

